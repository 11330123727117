var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap detail-main" },
    [
      _c("Header", {
        attrs: { mode: 2, detailType: "商圈", searchKey: _vm.searchKey },
      }),
      _c("MenuSide", {
        attrs: { list: _vm.menuList, activeMenu: _vm.activeMenu },
        on: { isOpen: _vm.isMenuOpen, onselect: _vm.menuSelect },
      }),
      _c(
        "div",
        {
          ref: "scroll",
          staticClass: "main-scroll",
          class: _vm.menuOpen ? "" : "main-scroll-change",
        },
        [
          _c(
            "div",
            { staticClass: "map-size" },
            [
              _vm.mapPolygons.length != 0
                ? _c("MapPanelLittle", {
                    attrs: {
                      id: 1,
                      markers: _vm.markers,
                      polygons: _vm.mapPolygons,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main-size" },
            [
              _c("SummaryCard", {
                attrs: {
                  cardType: 3,
                  cardInfo: _vm.detailInfo,
                  isVip: false,
                  collectShow: true,
                  claimShow: false,
                  shareShow: true,
                  detailID: _vm.tradeID,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "content-item",
                  attrs: { id: "shangyexiangmu" },
                },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("商业项目"),
                  ]),
                  _c("div", { staticClass: "project-size" }, [
                    _c(
                      "div",
                      { staticClass: "project-left" },
                      [
                        false
                          ? _c("MapPanel", {
                              ref: "mapProject",
                              attrs: { ignoreFitChina: true },
                            })
                          : _vm._e(),
                        _vm.projectMapPolygons.length != 0
                          ? _c("MapPanelLittle", {
                              attrs: {
                                id: 2,
                                isClick: true,
                                markers: _vm.markers,
                                polygons: _vm.projectMapPolygons,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "project-right" },
                      [
                        _vm._l(_vm.projectList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "pro_list" + index,
                              staticClass: "project-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.projectDetail(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "project-item-img",
                                attrs: { src: item.imgUrl, alt: "" },
                              }),
                              _c(
                                "div",
                                { staticClass: "project-item-content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "project-item-title" },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "project-item-introduction",
                                    },
                                    [
                                      _vm._v(
                                        " 地址：" +
                                          _vm._s(item.introduction) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "project-item-time" },
                                    [
                                      _vm._v(" 开业时间："),
                                      _c("span", [_vm._v(_vm._s(item.time))]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm.projectList.length == 0 ? _c("DataNull") : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "ruzhupinpai" } },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("商圈入驻品牌"),
                  ]),
                  _c("div", { staticClass: "brand-size" }, [
                    _c("div", { staticClass: "brand-left" }, [
                      _c("div", { staticClass: "chart-title" }, [
                        _vm._v("品牌业态分布（个）"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "chart-size" },
                        [
                          _c("BarChart", {
                            attrs: {
                              echartData: _vm.echartsData,
                              barWidth: 28,
                              barColor: _vm.barColorCon,
                              textColor: _vm.textColorCon,
                              yUnit: "",
                              echartSize: _vm.echartSizeCon,
                              maxLength: 8,
                              isHideY: true,
                              isHideYline: true,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.borderListLoadingStatus,
                            expression: "borderListLoadingStatus",
                          },
                        ],
                        staticClass: "brand-right",
                      },
                      [
                        _c("div", { staticClass: "brand-list-nav" }, [
                          _c("div", { staticClass: "nav-title" }, [
                            _vm._v("品牌详细信息"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "nav-list" },
                            _vm._l(_vm.echartsData, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "brand_nav" + index,
                                  staticClass: "nav-list-item",
                                  class: item.choose ? "active" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.brandItemClick(index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.name) +
                                      "(" +
                                      _vm._s(item.value) +
                                      ") "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-size" },
                          [
                            _vm._l(_vm.brandList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "brand_list" + index,
                                  staticClass: "brand-list-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.brandDetail(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "img-size" },
                                    [
                                      item.imgUrl
                                        ? _c("img", {
                                            staticClass: "brand-item-img",
                                            attrs: {
                                              src: item.imgUrl,
                                              alt: "",
                                            },
                                          })
                                        : [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "pic-name text-ellipsis2 center-middle-box",
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                            _c("img", {
                                              staticClass: "list-item-img",
                                              attrs: {
                                                src: require("@/assets/images/business/logobg2.png"),
                                                alt: "",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "brand-item-content" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "brand-item-title" },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "brand-item-introduction",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.introduction) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "brand-item-num" },
                                        [
                                          _vm._v(" 门店数量："),
                                          _c("span", [
                                            _vm._v(_vm._s(item.num) + "个"),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm.brandList.length == 0
                              ? _c("DataNull")
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "content-item",
                  attrs: { id: "shangchangquwei" },
                },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("商圈区位分析"),
                  ]),
                  _c("div", { staticClass: "location-wrap" }, [
                    _c("div", { staticClass: "location" }, [
                      _c(
                        "div",
                        { staticClass: "location-left" },
                        [
                          _c("MapPanel", {
                            ref: "mapPanel",
                            attrs: {
                              legendsObj: _vm.legendsObj,
                              ignoreFitChina: true,
                            },
                            on: { loaded: _vm.handleMapLoaded },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "location-right" },
                        [
                          _c("MapListTemplate", [
                            _c(
                              "div",
                              {
                                staticClass: "left-panel",
                                attrs: { slot: "leftPanel" },
                                slot: "leftPanel",
                              },
                              [
                                _c("div", { staticClass: "left-navs-tabs" }, [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.navs, function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: "nav_" + index,
                                          class: {
                                            cur: item.key === _vm.curNav,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.curNav = item.key
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "left-tabs-cont" },
                                  [
                                    _vm.curNav === "supportingFacilities"
                                      ? [
                                          _vm.wktPolygon
                                            ? _c("SupportingFacilities", {
                                                attrs: {
                                                  polygon: _vm.wktPolygon,
                                                  isShowOverView: false,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm.curNav === "formatAnalysis"
                                      ? [
                                          _c("FormatAnalysis", {
                                            attrs: {
                                              detailId: _vm.tradeID,
                                              polygon: _vm.wktPolygon,
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "zhoubiankequn" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _vm._v(" 周边客群分析 "),
                      _c("Icon", {
                        staticClass: "g-vip-icon",
                        attrs: { type: "ios-lock-outline" },
                      }),
                    ],
                    1
                  ),
                  _c("section", { staticClass: "g-vip-section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "g-vip-btn",
                        attrs: { role: "button" },
                        on: { click: _vm.toVip },
                      },
                      [_vm._v("去升级")]
                    ),
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/demo_pointer.png"),
                          width: "464",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "diliver" }),
                  ]),
                  _vm.mapPolygons && _vm.mapPolygons.length
                    ? _c("CircumCustomer", {
                        attrs: {
                          id: _vm.tradeID,
                          identify: "商圈",
                          "pos-info": { geom: _vm.mapPolygons[0].geometry },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "demo-item", attrs: { id: "keliu" } },
                [
                  _c("Icon", {
                    staticClass: "g-vip-icon2",
                    attrs: { type: "ios-lock-outline" },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/demo/business_detail_demo1_b.png"),
                    },
                  }),
                  _c("div", {
                    staticClass: "demo-btn2",
                    on: { click: _vm.vipClick },
                  }),
                  _c("div", {
                    staticClass: "anchor-box anchor-box1",
                    attrs: { id: "商圈到访" },
                  }),
                  _c("div", {
                    staticClass: "anchor-box anchor-box2",
                    attrs: { id: "商场到访" },
                  }),
                  _c("div", {
                    staticClass: "anchor-box anchor-box3",
                    attrs: { id: "业态到访" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-item", attrs: { id: "khhx" } },
            [
              _c("Icon", {
                staticClass: "g-vip-icon3",
                attrs: { type: "ios-lock-outline" },
              }),
              _c("div", {
                staticClass: "demo-btn2",
                on: { click: _vm.vipClick },
              }),
              _c("img", {
                attrs: {
                  src: require("../../assets/images/demo/business_detail_demo3_b.png"),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-item custom-item", attrs: { id: "depth" } },
            [
              _c(
                "div",
                {
                  ref: "offlineAnalysis",
                  staticClass: "anchor-box8",
                  attrs: { id: "offlineAnalysis" },
                },
                [_c("offline-analysis")],
                1
              ),
              _c(
                "div",
                { staticClass: "anchor-box9", attrs: { id: "onlineAnalysis" } },
                [_c("online-analysis")],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "page-bottom" }),
        ]
      ),
      _c("ModalUpgrade", {
        attrs: { isShow: _vm.isShow },
        on: {
          close: function ($event) {
            _vm.isShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }