<template>
  <div class="site-wrap detail-main">
    <Header :mode="2" detailType="商圈" :searchKey="searchKey"></Header>
    <MenuSide
      :list="menuList"
      :activeMenu="activeMenu"
      @isOpen="isMenuOpen"
      @onselect="menuSelect"
    ></MenuSide>
    <div
      class="main-scroll"
      ref="scroll"
      :class="menuOpen ? '' : 'main-scroll-change'"
    >
      <div class="map-size">
        <MapPanelLittle
          :id="1"
          :markers="markers"
          :polygons="mapPolygons"
          v-if="mapPolygons.length != 0"
        >
        </MapPanelLittle>
      </div>
      <div class="main-size">
        <!--isVip：是否为VIP身份；collectShow：是否显示收藏按钮；claimShow：是否显示认领按钮；shareShow：是否显示分享按钮；-->
        <SummaryCard
          :cardType="3"
          :cardInfo="detailInfo"
          :isVip="false"
          :collectShow="true"
          :claimShow="false"
          :shareShow="true"
          :detailID="tradeID"
        ></SummaryCard>
        <!--商圈商业项目-->
        <div class="content-item" id="shangyexiangmu">
          <div class="item-title">商业项目</div>
          <div class="project-size">
            <div class="project-left">
              <MapPanel
                ref="mapProject"
                :ignoreFitChina="true"
                v-if="false"
              ></MapPanel>
              <MapPanelLittle
                :id="2"
                :isClick="true"
                :markers="markers"
                :polygons="projectMapPolygons"
                v-if="projectMapPolygons.length != 0"
              >
              </MapPanelLittle>
            </div>
            <div class="project-right">
              <div
                class="project-list-item"
                v-for="(item, index) in projectList"
                :key="'pro_list' + index"
                @click="projectDetail(item)"
              >
                <img :src="item.imgUrl" class="project-item-img" alt="" />
                <div class="project-item-content">
                  <div class="project-item-title">{{ item.title }}</div>
                  <div class="project-item-introduction">
                    地址：{{ item.introduction }}
                  </div>
                  <div class="project-item-time">
                    开业时间：<span>{{ item.time }}</span>
                  </div>
                </div>
              </div>
              <DataNull v-if="projectList.length == 0"></DataNull>
            </div>
          </div>
        </div>
        <!--商圈入驻品牌-->
        <div class="content-item" id="ruzhupinpai">
          <div class="item-title">商圈入驻品牌</div>
          <div class="brand-size">
            <div class="brand-left">
              <div class="chart-title">品牌业态分布（个）</div>
              <div class="chart-size">
                <BarChart
                  :echartData="echartsData"
                  :barWidth="28"
                  :barColor="barColorCon"
                  :textColor="textColorCon"
                  :yUnit="''"
                  :echartSize="echartSizeCon"
                  :maxLength="8"
                  :isHideY="true"
                  :isHideYline="true"
                >
                </BarChart>
              </div>
            </div>
            <div class="brand-right" v-loading="borderListLoadingStatus">
              <div class="brand-list-nav">
                <div class="nav-title">品牌详细信息</div>
                <div class="nav-list">
                  <div
                    class="nav-list-item"
                    :class="item.choose ? 'active' : ''"
                    v-for="(item, index) in echartsData"
                    :key="'brand_nav' + index"
                    @click="brandItemClick(index)"
                  >
                    {{ item.name }}({{ item.value }})
                  </div>
                </div>
              </div>
              <div class="list-size">
                <div
                  class="brand-list-item"
                  v-for="(item, index) in brandList"
                  :key="'brand_list' + index"
                  @click="brandDetail(item)"
                >
                  <div class="img-size">
                    <img
                      :src="item.imgUrl"
                      class="brand-item-img"
                      alt=""
                      v-if="item.imgUrl"
                    />
                    <template v-else>
                      <span class="pic-name text-ellipsis2 center-middle-box">{{ item.title }}</span>
                      <img class="list-item-img" src="@/assets/images/business/logobg2.png" alt="">
                    </template>
                  </div>
                  <div class="brand-item-content">
                    <div class="brand-item-title">{{ item.title }}</div>
                    <div class="brand-item-introduction">
                      {{ item.introduction }}
                    </div>
                    <div class="brand-item-num">
                      门店数量：<span>{{ item.num }}个</span>
                    </div>
                  </div>
                </div>
                <DataNull v-if="brandList.length == 0"></DataNull>
              </div>
            </div>
          </div>
        </div>
        <!-- 商场区位分析 -->
        <div class="content-item" id="shangchangquwei">
          <div class="item-title">商圈区位分析</div>
          <div class="location-wrap">
            <div class="location">
              <div class="location-left">
                <MapPanel
                  ref="mapPanel"
                  :legendsObj="legendsObj"
                  :ignoreFitChina="true"
                  @loaded="handleMapLoaded"
                >
                </MapPanel>
              </div>
              <div class="location-right">
                <MapListTemplate>
                  <div class="left-panel" slot="leftPanel">
                    <div class="left-navs-tabs">
                      <ul>
                        <li
                          v-for="(item, index) in navs"
                          :key="'nav_' + index"
                          :class="{ cur: item.key === curNav }"
                          @click="curNav = item.key"
                        >
                          {{ item.label }}
                        </li>
                      </ul>
                    </div>

                    <div class="left-tabs-cont">
                      <!-- 配套信息 -->
                      <template v-if="curNav === 'supportingFacilities'">
                        <SupportingFacilities
                          v-if="wktPolygon"
                          :polygon="wktPolygon"
                          :isShowOverView="false"
                        ></SupportingFacilities>
                      </template>
                      <!-- 业态分析 -->
                      <template v-if="curNav === 'formatAnalysis'">
                        <FormatAnalysis :detailId="tradeID" :polygon="wktPolygon"></FormatAnalysis>
                      </template>
                    </div>
                  </div>
                </MapListTemplate>
              </div>
            </div>
          </div>
        </div>
        <!--周边客群分析-->
        <div class="content-item" id="zhoubiankequn">
          <div class="item-title">
            周边客群分析
            <!-- <img
              class="g-vip-icon"
              :src="require('@/assets/images/vip/g-vip.png')"
              width="21"
              height="10"
            /> -->

            <Icon  type="ios-lock-outline"   class="g-vip-icon"/>
          </div>
          <section class="g-vip-section">
            <div role="button" class="g-vip-btn" @click="toVip">去升级</div>
            <div>
              <img
                :src="require('@/assets/images/demo_pointer.png')"
                width="464"
              />
            </div>
            <div class="diliver"></div>
          </section>
          <CircumCustomer v-if="mapPolygons && mapPolygons.length" :id="tradeID" :identify="'商圈'" :pos-info="{ geom: mapPolygons[0].geometry }"></CircumCustomer>
        </div>
        <!--到访分析-->
        <div class="demo-item" id="keliu">
           <Icon type="ios-lock-outline" class="g-vip-icon2" />
          <!-- <img src="../../assets/images/demo/business_detail_demo1.png" /> -->
          <img src="../../assets/images/demo/business_detail_demo1_b.png" />
          <div class="demo-btn2" @click="vipClick"></div>
          <div class="anchor-box anchor-box1" id="商圈到访">
            <!--demo图片，此div用于锚点定位-->
          </div>
          <div class="anchor-box anchor-box2" id="商场到访">
            <!--demo图片，此div用于锚点定位-->
          </div>
          <div class="anchor-box anchor-box3" id="业态到访">
            <!--demo图片，此div用于锚点定位-->
          </div>
          <!-- <div class="anchor-box anchor-box4" id="客流来源分析"> -->
            <!--demo图片，此div用于锚点定位-->
          </div>
      </div>
        <!--到访分析-->
        <!-- <div class="demo-item" id="daofang">
          <img src="../../assets/images/demo/business_detail_demo2.png" />
          <div class="demo-btn" @click="vipClick"></div>
          <div class="anchor-box anchor-box5" id="进场分析"> -->
            <!--demo图片，此div用于锚点定位-->
          <!-- </div> -->
          <!-- <div class="anchor-box anchor-box6" id="停留分析"> -->
            <!--demo图片，此div用于锚点定位-->
          <!-- </div> -->
        <!-- </div> -->
        <!-- 客户画像 -->
        <div class="demo-item" id="khhx">
           <Icon type="ios-lock-outline" class="g-vip-icon3" />
            <div class="demo-btn2" @click="vipClick"></div>
            <!-- <img src="../../assets/images/demo/business_detail_demo3.png" /> -->
            <img src="../../assets/images/demo/business_detail_demo3_b.png" />
        </div>
        <div class="demo-item custom-item" id="depth">
          <div class="anchor-box8" ref="offlineAnalysis" id="offlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <offline-analysis></offline-analysis>
          </div>
          <div class="anchor-box9" id="onlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <online-analysis></online-analysis>
          </div>
        </div>
        <div class="page-bottom"></div>
      </div>
      <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
    </div>

  </div>
</template>

<script>
import ModalUpgrade from "@/components/ModalUpgrade.vue"
import SummaryCard from "../../components/SummaryCard.vue";
import MenuSide from "../../components/MenuSide.vue";
import { GetCommonInfo, GetDetailBrowsingRecord } from "@/service";
import BarChart from "../../components/chart/barChart.vue";
import MapPanelLittle from "@/components/MapPanelLittle.vue";
import MapPanel from "../../components/MapPanel.vue";
import CircumCustomer from "../../components/cards/CircumCustomer.vue";
import FormatAnalysis from "../../components/business/FormatAnalysis.vue";
import { formatFieldsList, cloneObj, formatFieldsList2 } from "js/utils.js";
import { any2geom, geom2wkt, wkb2geom, wkt2geom } from "js/map/map.utils.js";
import { login, isLogin } from "js/utils.js";
import { openNewWindow } from "@/assets/javascript/utils.js";
import DataNull from "../../components/chart/dataNull.vue";
import offlineAnalysis from "../../components/depthAnalysis/offline.vue";
import onlineAnalysis from "../../components/depthAnalysis/online.vue";
import SupportingFacilities from "@/components/location/SupportingFacilities.vue"

export default {
  metaInfo() {
    let list = ["查商圈"];
    if (!this.businessName) {
      return null;
    }
    list.unshift(this.cityName);
    list.unshift(this.businessName);

    return {
      title: list.join(" - "),
       meta: [
      {
        name: "keywords",
        content:
          `千里目,${this.cityName},${this.businessName},商圈数据,商圈分析,商圈入驻品牌,商圈入驻商场,商圈人口规模,商圈配套,住宅小区,写字楼,商场,商超,便利店,学校,医院,公司企业,交通,客群特征,商业业态,商圈到访客流,客流来源,用户停留时长,用户进场时段,新增客流,流失客流,客流统计,实时客流`,
      },
      {
        name: "description",
        content:
          `${this.cityName}${this.businessName}商业数据分析，包含商圈入驻品牌、入驻商场、人口规模、配套设施、住宅小区、写字楼、商场、商超、便利店、学校、医院、公司企业、交通、客群特征、商业业态、到访实时客流和来源、用户停留时长和进场时段、新增和流失客流等详细数据。`,
      },
       ]
    };
  },
  data() {
    return {
      isShow:false,
      menuOpen: true,
      tradeID: "", //测试ID：1
      detailInfo: [],
      businessName: "", // 商圈名称

      mapPolygons: [],
      projectMapPolygons: [],
      wktPolygon: '',
      echartsData: [],
      echartSizeCon: { x: 65, x2: 30, y: 25, y2: 25 },
      barColorCon: "#3519FB",
      textColorCon: "#848295",

      projectList: [],

      brandCategory: "",
      brandList: [],
      // 左侧菜单选中值
      activeMenu: "",
      // 左侧菜单调整自检名称，动态路由使用
      componentName: "",
      // 左侧菜单列表，key值临时定义使用时需修改
      menuList: [
        {
          key: "shangyexiangmu",
          name: "商业项目",
          title: "项目",
          isComponet: false,
        },
        {
          key: "ruzhupinpai",
          name: "入驻品牌",
          title: "品牌",
          isComponet: false,
        },
        {
          key: "shangchangquwei",
          name: "区位分析",
          title: "区位",
        },
        {
          key: "zhoubiankequn",
          name: "周边客群",
          title: "客群",
        },
        {
          key: "到访分析",
          name: "到访分析",
          title: "到访",
          children: [
            {
              key: "商圈到访",
              name: "商圈到访",
              icon: require("@/assets/images/icon/menu_sq.png"),
              isComponet: false,
            },
            {
              key: "商场到访",
              name: "商场到访",
              icon: require("@/assets/images/icon/menu_jk.png"),
              isComponet: false,
            },
            {
              key: "业态到访",
              name: "业态到访",
              icon: require("@/assets/images/icon/menu_xl.png"),
              isComponet: false,
            },
            // {
            //   key: "客流来源分析",
            //   name: "客流来源分析",
            //   icon: require("@/assets/images/icon/menu_kl.png"),
            //   isComponet: false,
            // },
          ],
        },
        {
          key: "khhx",
          name: "客户画像",
          title: "画像",
        },
        // {
        //   key: "到访分析",
        //   name: "到访分析",
        //   title: "到访",
        //   children: [
        //     {
        //       key: "进场分析",
        //       name: "进场分析",
        //       icon: require("@/assets/images/icon/menu_df.png"),
        //       isComponet: false,
        //     },
        //     {
        //       key: "停留分析",
        //       name: "停留分析",
        //       icon: require("@/assets/images/icon/menu_kh.png"),
        //       isComponet: false,
        //     },
        //   ],
        // },
        {
          key: "depth",
          name: "深度分析",
          title: "深度分析",
          children: [
            {
              key: "offlineAnalysis",
              name: "线下数据分析",
              icon: require("@/assets/images/icon/menu_xx.png"),
              isComponet: false,
            },
            {
              key: "onlineAnalysis",
              name: "线上营销分析",
              icon: require("@/assets/images/icon/menu_xs.png"),
              isComponet: false,
            },
          ],
        },
      ],
      legends: [
        {
          title: "住宅区",
          color: "#5F6AEB",
          key: "community",
        },
        {
          title: "商业区",
          color: "#986FFF",
          key: "business",
        },
        {
          title: "写字楼",
          color: "#FD767D",
          key: "office",
        },
        {
          title: "学校",
          color: "#FF9800",
          key: "school",
        },
        {
          title: "医院",
          color: "#FCC44D",
          key: "hospital",
        },
        {
          title: "地铁站",
          color: "#3CD19C",
          key: "subway",
        },
        {
          title: "公交站",
          color: "#28C7F3",
          key: "bus",
        },
      ],
      isMapLoaded: false,
      navs: [
        { label: "配套信息", key: "supportingFacilities" },
        { label: "业态分析", key: "formatAnalysis" },
      ],
      curNav: "supportingFacilities",
      markers: [],
      borderListLoadingStatus: false, //商圈入住品牌-品牌详情信息loading状态
      searchKey: "",
      cityName:""
    };
  },
  computed: {
    scrollBox() {
      return this.$refs.scroll;
    },
    legendsObj() {
      return {
        title: "全部配套",
        list: this.legends,
      };
    },
  },
  methods: {
    isMenuOpen(isOpen) {
      this.menuOpen = isOpen;
    },
    isLogin,
    gotoLogin: login,
    toVip() {
      this.isShow = true;
      // window.open("/vip", "_blank");
    },
    menuSelect(name, isComponent) {
      if (isComponent) {
        // 线下营销或非锚点定位走动态路由
        this.componentName = name;
      } else {
        this.componentName = "";
        document.getElementById(name) &&
          document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }
    },
    //锚点和导航联动
    handleScroll() {
      if (this.componentName) return;
      //获取dom滚动距离
      const scrollTop = this.scrollBox.scrollTop;
      //商圈商业项目锚点
      let oneTop = this.$el.querySelector("#shangyexiangmu").offsetTop;
      //商圈入驻品牌锚点
      let twoTop = this.$el.querySelector("#ruzhupinpai").offsetTop;
      //滚动商圈区位分析分析
      let threeTop = this.$el.querySelector("#shangchangquwei").offsetTop;
      //客流分析
      let fourTop = this.$el.querySelector("#keliu").offsetTop;
      let fourOneTop = fourTop + this.$el.querySelector("#商圈到访").offsetTop;
      let fourTwoTop = fourTop + this.$el.querySelector("#商场到访").offsetTop;
      let fourThreeTop =
        fourTop + this.$el.querySelector("#业态到访").offsetTop;
      // let fourFourTop =
      //   fourTop + this.$el.querySelector("#客流来源分析").offsetTop;
      //到访分析
      // let fiveTop = this.$el.querySelector("#daofang").offsetTop;
      // let fiveOneTop = fiveTop + this.$el.querySelector("#进场分析").offsetTop;
      // let fiveTwoTop = fiveTop + this.$el.querySelector("#停留分析").offsetTop;
      //周边客群分析
      let sixTop = this.$el.querySelector("#zhoubiankequn").offsetTop;
      let sevenTop = this.$el.querySelector("#depth").offsetTop;
      let sevenOneTop =
        sevenTop + this.$el.querySelector("#offlineAnalysis").offsetTop;
      let sevenTwoTop =
        sevenTop + this.$el.querySelector("#onlineAnalysis").offsetTop;

      let khhxTop = this.$el.querySelector("#khhx").offsetTop;
      if (scrollTop >= oneTop && scrollTop < twoTop) {
        //滚动商圈商业项目分布
        this.activeMenu = "shangyexiangmu";
      } else if (scrollTop >= twoTop && scrollTop < threeTop) {
        //滚动商圈入驻品牌分析
        this.activeMenu = "ruzhupinpai";
      } else if (scrollTop >= threeTop && scrollTop < sixTop) {
        //滚动商圈区位分析分析
        this.activeMenu = "shangchangquwei";
      } else if (scrollTop >= sixTop && scrollTop < fourOneTop) {
        //滚动周边客群分析
        this.activeMenu = "zhoubiankequn";
      } else if (scrollTop >= fourOneTop && scrollTop < fourTwoTop) {
        //客流分析--商圈客流
        this.activeMenu = "商圈到访";
      } else if (scrollTop >= fourTwoTop && scrollTop < fourThreeTop) {
        //客流分析--商场客流
        this.activeMenu = "商场到访";
      } else if (scrollTop >= fourThreeTop && scrollTop < khhxTop) {
        //客流分析--业态客流
        this.activeMenu = "业态到访";
      } else if (scrollTop >= khhxTop && scrollTop < sevenOneTop) {
        this.activeMenu = "khhx";
      }
      // else if (scrollTop >= fiveOneTop && scrollTop < fiveTwoTop) {
      //   //到访分析--进店分析
      //   this.activeMenu = "进场分析";
      // } else if (scrollTop >= fiveTwoTop && scrollTop < sevenTop) {
      //   //到访分析--停留分析
      //   this.activeMenu = "停留分析";
      // }
      else if (scrollTop >= sevenOneTop && scrollTop < sevenTwoTop) {
        this.activeMenu = "offlineAnalysis";
      } else if (scrollTop >= sevenTwoTop) {
        this.activeMenu = "onlineAnalysis";
      }
    },
    //获取详情信息（汇总卡片信息）
    async getDetail() {
      let params = {
        identify: "sg-data-商圈-商圈总览",
        filters: [
          {
            field: "商圈id",
            cond: 0,
            value: [this.tradeID],
          },
        ],
        fields: [
          "占地面积(km2)",
          "品牌数",
          "所在城市",
          "geom",
          "商圈级别",
          "商业数",
          "商圈名称",
          "商圈等级",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let searchGeom = any2geom(res.data.items[0][3]);
        this.searchGeom = JSON.stringify(searchGeom);
        for (let i in res.data.items[0]) {
          this.detailInfo.push(res.data.items[0][i]);
        }
        this.businessName = this.detailInfo[6];

        //地图数据
        let polygon = any2geom(res.data.items[0][3] || "");
        let features = [];
        let feature = {
          type: "Feature",
          geometry: JSON.parse(JSON.stringify(polygon)),
        };
        features.push(feature);
        this.mapPolygons = features;
        this.projectMapPolygons = features;
        this.wktPolygon = geom2wkt(polygon)
        this.getDetail2();
      }
    },
    //获取详情信息其他（汇总卡片信息）
    async getDetail2() {
      let params = {
        identify: "sg-data-商圈-商圈总览-其他",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.wktPolygon],
          },
          {
            field: 'city',
            cond: 0,
            value: [this.detailInfo[2]]
          }
        ],
        fields: [
          "人均购物中心面积",
          "城市",
          "公共交通",
          "公司企业",
          "写字楼",
          "人口规模",
          "学校",
          "商业项目",
          "小区",
          "医院",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        for (let i in res.data.items[0]) {
          this.detailInfo.push(res.data.items[0][i]);
        }
      }
    },

    //商圈商业项目--右侧列表
    async getProjectList() {
      let params = {
        identify: "sg-data-商圈-商业项目-商业项目详细信息",
        filters: [
          {
            field: "商圈id",
            cond: 0,
            value: [this.tradeID],
          },
        ],
        fields: [
          "地址",
          "商场code",
          "商场名称",
          "开业时间",
          "图片url",
          "排序id",
          "geom",
        ],
        pages: {
          page: 1,
          size: 100,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        for (let i in res.data.items) {
          this.projectList.push({
            id: res.data.items[i][1] || "",
            imgUrl: res.data.items[i][4] || "",
            title: res.data.items[i][2] || "",
            introduction: res.data.items[i][0] || "",
            time: res.data.items[i][3] || "",
          });
          this.projectList = JSON.parse(JSON.stringify(this.projectList));
          //获取商场定位数据
          this.getBusinessLocal(res.data.items[i][1]);
        }
      }
    },
    //获取商场定位数据
    async getBusinessLocal(mallCode) {
      let params = {
        identify: "sg-data-商场-基础信息",
        filters: [
          {
            field: "商场唯一code",
            cond: 0,
            value: [mallCode],
          },
        ],
        fields: ["geom", "名称", "地址", "开业时间"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data || {});
        //整理地图点位数据
        let marker = any2geom(list[0]["geom"] || "");
        let feature = {
          type: "Feature",
          geometry: JSON.parse(JSON.stringify(marker)),
          properties: {
            code: mallCode,
            name: list[0]["名称"],
            addr: list[0]["地址"],
            startTime: list[0]["开业时间"],
          },
        };
        this.markers.push(feature);
      }
    },
    //商圈商业项目，点击打开详情
    async projectDetail(item) {
      //this.$router.push("/mallDetail?id=" + item.id + "");
      const res = await GetDetailBrowsingRecord({
        module: "mallDetail",
        detailID: item.id,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/mallDetail", {
          id: item.id,
        });
      }
    },

    //商圈入驻品牌
    async getBrand() {
      let params = {
        identify: "sg-data-商圈-商圈品牌-品牌分布",
        filters: [
          {
            field: "商圈id",
            cond: 0,
            value: [this.tradeID],
          },
        ],
        fields: ["大类", "大类数量"],
        sort :["大类数量","desc"]
      };
      let res = await GetCommonInfo(params);

      if (res && res.code === 200) {
        //console.log(res.data,888)
        for (let i in res.data.items) {
          this.echartsData.push({
            name: res.data.items[i][0],
            value: String(res.data.items[i][1]),
            choose: false,
          });
        }
        //设置默认列表状态
        if (this.echartsData.length != 0) {
          this.echartsData[0].choose = true;
          this.brandCategory = this.echartsData[0].name;
          this.getBrandList();
        }
        this.echartsData = JSON.parse(JSON.stringify(this.echartsData));
      }
    },
    //商圈入驻品牌(右侧列表切换)
    brandItemClick(index) {
      if (!this.isLogin() && index > 0) {
        document.querySelector("body").classList.add("vip-dialog-wrapper");
        document.querySelector("body").classList.add("visitor-dialog-wrapper");
        const contentHtml = `<section class="g-business-detail-visitor">
            <p>登录后可以查看全部信息</p>
        </section>`;
        this.$Modal.confirm({
          title: "",
          content: contentHtml,
          width: "453px",
          cancelText: "占位",
          okText: "立即登录",
          onOk: () => {
            this.gotoLogin();
            setTimeout(() => {
              document
                .querySelector("body")
                .classList.remove("vip-dialog-wrapper");
              document
                .querySelector("body")
                .classList.remove("visitor-dialog-wrapper");
            }, 500);
          },
          onCancel: () => {
            setTimeout(() => {
              document
                .querySelector("body")
                .classList.remove("vip-dialog-wrapper");
              document
                .querySelector("body")
                .classList.remove("visitor-dialog-wrapper");
            }, 500);
          },
        });
        return;
      }
      for (let i in this.echartsData) {
        this.echartsData[i].choose = false;
      }
      this.echartsData[index].choose = true;
      this.brandCategory = this.echartsData[index].name;
      this.brandList = [];
      this.getBrandList();
    },
    //商圈入驻品牌(右侧详情)
    async getBrandList() {
      this.borderListLoadingStatus = true;
      let params = {
        identify: "sg-data-商圈-商圈品牌-品牌分布-详情",
        filters: [
          {
            field: "商圈id",
            cond: 0,
            value: [this.tradeID],
          },
          {
            field: "大类",
            cond: 0,
            value: [this.brandCategory],
          },
        ],
         sort: ["value2", "asc"],
        fields: [
          "门店数量",
          "公司名称",
          "品牌id",
          "品牌名",
          "品牌url",
          "大类",
          "顺序id(数量降序)",
        ],
      };
      let res = await GetCommonInfo(params);
      this.borderListLoadingStatus = false;

      if (res && res.code === 200) {
        for (let i in res.data.items) {
          this.brandList.push({
            id: res.data.items[i][2] || "",
            imgUrl: res.data.items[i][4] || "",
            title: res.data.items[i][3] || "",
            introduction: res.data.items[i][1] || "",
            num: res.data.items[i][0] || 0,
          });
        }
      }
    },
    //商圈入驻品牌，点击打开详情
    async brandDetail(item) {
      //this.$router.push("/brandDetail?id=" + item.id + "");
      const res = await GetDetailBrowsingRecord({
        module: "brandDetail",
        detailID: item.id,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/brandDetail", {
          id: item.id,
        });
      }
    },

    // 商圈区位分析模块
    // 初始化地图
    handleMapLoaded() {
      this.isMapLoaded = true;
      this.queryLegendsPois();
    },
    // 获取地图列表
    // 查询poi
    queryLegendsPois() {
      let feature = {
        type: "Feature",
        geometry: JSON.parse(this.searchGeom),
        properties: {},
      };
      let features = [feature];
      let options = {
        strokeColor: "#3519FB",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#3519FB",
        fillOpacity: 0.2,
        strokeStyle: "solid",
      };
      this.mapPanel.fitBounds(features, true, [50, 50, 50, 50]);
      this.mapPanel.setPolygons("polygons", features, options);
      let geom = this.searchGeom;
      for (let i = 0, n = this.legends.length; i < n; i++) {
        this.queryAreaPois(i, geom);
      }
      this.isSearchResult = true;
    },
    // 请求选中面积中的配套信息
    async queryAreaPois(legendIndex, geom) {
      let key = this.legends[legendIndex].key;
      let cate = this.legends[legendIndex].title;
      let identify = this.$store.state.tabsIdentifyLocation[key];
      let fields = cloneObj(this.$store.state.tabsLocationFields[key]);
      fields.push("位置");
      let polygon = geom2wkt(geom);
      let params = {
        identify: identify,
        fields: fields,
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [polygon],
          },
        ],
        pages: {
          page: 1,
          size: 10000,
        },
      };
      let extObj = {
        均价: "元/㎡",
        平均租金: "元/㎡/天",
        占地面积: "㎡",
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data);
        let features = [];
        for (let i = 0, n = list.length; i < n; i++) {
          let item = list[i];
          let attrs = [];
          attrs.push({
            label: "分类",
            value: cate,
          });
          for (let key in item) {
            if (key === "位置") {
              continue;
            }
            let val = item[key];
            if (val) {
              if (extObj[key]) {
                val += extObj[key];
              }
            }
            attrs.push({
              label: key,
              value: val || "--",
            });
          }
          item.attrs = attrs;
          item.isPoi = true;
          let geom = item["位置"];
          if (!geom) {
            continue;
          }
          geom = wkt2geom(geom);
          let feature = {
            type: "Feature",
            properties: item,
            geometry: geom,
          };
          features.push(feature);
        }
        this.mapPanel.setLegendSource(legendIndex, features);
      }
      // let key = this.legends[legendIndex].key;
      // let field = null;
      // if (key === "subway") {
      //   field = {
      //     field: "交通类型", //field5
      //     cond: 0,
      //     value: ["地铁站"],
      //   };
      // } else if (key === "bus") {
      //   field = {
      //     field: "交通类型", //field5
      //     cond: 0,
      //     value: ["公交站"],
      //   };
      // }
      // let filters = [
      //   {
      //     field: "key1",
      //     cond: 0,
      //     value: [this.tradeID],
      //   },
      // ];
      // if (field) {
      //   filters.push(field);
      // }
      // let identify = this.$store.state.tabsIdentifyBusiness[key];
      // let fields = ["geom"];
      // let params = {
      //   identify: identify,
      //   fields: fields,
      //   filters: filters,
      //   pages: {
      //     page: 1,
      //     size: 10000,
      //   },
      // };
      // let res = await GetCommonInfo(params);
      // if (res && res.code === 200) {
      //   let list = res.data.items || [];
      //   let features = [];
      //   for (let i = 0, n = list.length; i < n; i++) {
      //     let item = list[i];
      //     let attrs = [];
      //     item.attrs = attrs;
      //     item.isPoi = true;
      //     let geom = wkb2geom(item[0]);
      //     let feature = {
      //       type: "Feature",
      //       properties: item,
      //       geometry: geom,
      //     };
      //     features.push(feature);
      //   }
      //   this.mapPanel.setLegendSource(legendIndex, features);
      // }
    },

    //开通VIP
    vipClick() {
      // window.open("/vip", "_blank");
      this.isShow= true;
    },
  },
  async mounted() {
    let searchKey = this.$route.query.key;
    this.cityName = this.$route.query.cityName || "";
    if (searchKey) {
      this.searchKey = searchKey;
    }
    //获取地址栏参数
    let getID = this.$route.query.id;
    if (getID) {
      this.tradeID = getID;
    }
    //获取详情信息
    await this.getDetail();
    //获取商圈商业项目数据
    this.getProjectList();
    //获取商圈入驻品牌数据
    this.getBrand();

    this.scrollBox.addEventListener("scroll", this.handleScroll);
    this.mapPanel = this.$refs.mapPanel;
  },
  components: {
    offlineAnalysis,
    onlineAnalysis,
    SummaryCard,
    MenuSide,
    BarChart,
    MapPanelLittle,
    MapPanel,
    CircumCustomer,
    FormatAnalysis,
    DataNull,
    SupportingFacilities,
    ModalUpgrade

  },
};
</script>

<style lang="less">
@import "../../assets/stylesheets/table.less";

.g-business-detail-visitor {
  text-align: center;
  margin-top: 40px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 30px;
  }
}

.detail-main {
  display: flex;
  flex-wrap: wrap;

  .main-scroll {
    width: calc(100% - 200px);
    height: calc(100% - 101px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    background: #f6f6fa;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;

    .map-size {
      width: 100%;
      height: 200px;
      background: #d7d7d7;
    }

    .main-size {
      width: 1120px;
      min-width: 1120px;
      margin-top: -62px;
      position: relative;
      z-index: 199;
    }

    .content-item {
      width: 100%;
      height: auto;
      position: relative;
      margin: 20px 0 0 0;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .item-title {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        box-sizing: border-box;
        padding: 0 0 0 20px;
        border-bottom: 1px solid #e0e0e0;
      }

      .project-size {
        width: 100%;
        height: 525px;
        box-sizing: border-box;
        padding: 24px;
        display: flex;

        .project-left {
          width: 670px;
          height: 100%;
        }

        .project-right {
          width: calc(100% - 670px);
          height: 100%;
          background: #ffffff;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          overflow-y: auto;

          .project-list-item {
            width: 100%;
            height: 118px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            line-height: 1;
            cursor: pointer;
            border-bottom: 1px solid #eeeeee;

            .project-item-img {
              width: 80px;
              height: 80px;
              margin: 0 10px 0 20px;
            }

            .project-item-content {
              width: calc(100% - 110px);
              display: flex;
              align-content: flex-start;
              flex-wrap: wrap;
            }

            .project-item-title {
              width: 100%;
              font-size: 16px;
              font-weight: 500;
              color: #000000;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .project-item-introduction {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.8);
              margin-top: 17px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .project-item-time {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.7);
              margin-top: 13px;

              span {
                color: #000000;
              }
            }
          }
        }
      }

      .brand-size {
        width: 100%;
        height: 365px;
        display: flex;

        .brand-left {
          width: 560px;
          height: 365px;
          border-right: 1px solid #eeeeee;

          .chart-title {
            width: 100%;
            box-sizing: border-box;
            padding: 25px 30px 0 30px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .chart-size {
            width: 100%;
            height: 285px;
            margin-top: 10px;
          }
        }

        .brand-right {
          width: calc(100% - 560px);
          height: 365px;
          display: flex;
          position: relative;
          .brand-list-nav {
            width: 170px;
            height: 365px;

            .nav-title {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.7);
              box-sizing: border-box;
              padding: 25px 0 30px 30px;
            }

            .nav-list {
              width: 100%;
              height: 260px;
              overflow-y: auto;

              .nav-list-item {
                width: 110px;
                height: 32px;
                background: #ffffff;
                border-radius: 4px;
                font-size: 14px;
                color: #848295;
                display: flex;
                align-items: center;
                margin: 0 0 18px 16px;
                box-sizing: border-box;
                padding: 0 0 0 14px;
                cursor: pointer;
              }

              .active {
                color: #000000;
                font-weight: bold;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
              }
            }
          }

          .list-size {
            width: 362px;
            height: 318px;
            background: #ffffff;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
            border-radius: 4px;
            margin: 23px 0 0 0;
            overflow-y: auto;
            box-sizing: border-box;
            padding: 20px 20px 0 20px;

            .brand-list-item {
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              line-height: 1;
              margin: 0 0 20px 0;
              cursor: pointer;

              .img-size {
                width: 80px;
                height: 80px;
                margin-right: 10px;
                border-radius: 6px;
                overflow: hidden;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .brand-item-img {
                  width: auto;
                  height: auto;
                  max-width: 80px;
                  max-height: 80px;
                }
                .pic-name {
                  width: 54px;
                  text-align: center;
                  font-size: 18px;
                  font-weight: 600;
                  color: #FFFFFF;
                  line-height: 1.5;
                }
              }
              .img-size::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                z-index: 9;
              }

              .brand-item-content {
                width: calc(100% - 90px);
                display: flex;
                align-content: flex-start;
                flex-wrap: wrap;
              }

              .brand-item-title {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .brand-item-introduction {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
                margin-top: 17px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .brand-item-num {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.7);
                margin-top: 13px;

                span {
                  color: #000000;
                }
              }
            }
          }
        }
      }

      .location-wrap {
        width: 100%;
        height: 580px;
        padding: 30px;
        .location {
          height: 100%;
          border: 1px solid #eeeeee;
          display: flex;
          border-radius: 4px;
          overflow: hidden;
        }
        .location-left {
          width: calc(100% - 420px);
          height: 100%;
        }
        .location-right {
          width: 420px;
          height: 100%;
          position: relative;
          &:hover {
            .expand-btn {
              display: none !important;
            }
          }
        }
      }
    }

    .demo-item {
      width: 1128px;
      margin: 15px 0 0 -4px;
      display: flex;
      position: relative;
 .g-vip-icon2{
        position: absolute;
        left: 114px;
        top: 18px;
        font-size: 16px;
      }
      .g-vip-icon3{
         position: absolute;
        left: 164px;
        top: 18px;
        font-size: 16px;
      }
      img {
        width: 100%;
      }
      .demo-btn {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 122px;
        left: 465px;
        cursor: pointer;
      }
      .demo-btn2 {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 386px;
        left: 464px;
        cursor: pointer;
      }
      .anchor-box {
        width: 100%;
        height: 30px;
        position: absolute;
        opacity: 0;
      }
      .anchor-box1 {
        top: 770px;
        left: 0;
      }
      .anchor-box2 {
        top: 3070px;
        left: 0;
      }
      .anchor-box3 {
        top: 4090px;
        left: 0;
      }
      .anchor-box4 {
        top: 2000px;
        left: 0;
      }
      .anchor-box5 {
        top: 0;
        left: 0;
      }
      .anchor-box6 {
        top: 880px;
        left: 0;
      }
      .anchor-box9 {
        margin-top: 20px;
      }
    }

    .custom-item {
      flex-direction: column;
      img {
        width: auto;
      }
    }
    .page-bottom {
      width: 100%;
      height: 60px;
    }
  }

  .main-scroll-change {
    width: calc(100% - 74px);
    transition: width 0.3s;
  }
}
</style>
