var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CustomTabs", {
        attrs: { tabList: _vm.tabList },
        on: { activeTab: _vm.activeTab },
      }),
      _c("CustomData", { attrs: { dataList: _vm.dataList } }),
      _c(
        "CustomCard",
        { attrs: { title: "", height: "300px" } },
        [
          _c("pieChart", {
            attrs: { echartData: _vm.pieChartData, legendShow: true },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }